import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { constantVariables } from '@shared/general/configs';
import { LogoutMutationService, RefreshTokenMutationService } from '@shared/graphql/api.generated';
import { IUser } from '@shared/models/user.interface';
import { jwtDecode } from 'jwt-decode';
import { catchError, tap } from 'rxjs';
import { SnackbarService, SnackbarType } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  userInfo = signal<IUser | undefined>(undefined);

  private readonly snackbar = inject(SnackbarService);
  private readonly router = inject(Router);
  private readonly refreshTokenMutationService = inject(RefreshTokenMutationService);
  private readonly logoutMutationService = inject(LogoutMutationService);

  constructor() {
    const atk = localStorage.getItem(constantVariables.accessToken);
    if (atk) {
      this.setUserInfoPayload(atk);
    }
  }

  refreshToken() {
    return this.refreshTokenMutationService.mutate().pipe(
      tap((res) => {
        this.setUserInfo(res.data?.refreshToken?.data?.at!, res.data?.refreshToken?.data?.rt!);
        return res;
      }),
      catchError((e) => {
        this.logout();
        throw e;
      }),
    );
  }

  logout() {
    this.logoutMutationService.mutate().subscribe({
      next: (res) => {
        localStorage.removeItem(constantVariables.accessToken);
        localStorage.removeItem(constantVariables.refreshToken);
        localStorage.removeItem(constantVariables.selectedProduct);
        this.snackbar.showSnackbar(res.data?.logout?.message!, SnackbarType.error);
        this.router.navigateByUrl('/auth');
      },
      error: (e) => {
        localStorage.removeItem(constantVariables.accessToken);
        localStorage.removeItem(constantVariables.refreshToken);
        localStorage.removeItem(constantVariables.selectedProduct);
        this.snackbar.showSnackbar(e.networkError.message, SnackbarType.error);
        this.router.navigateByUrl('/auth');
      },
    });
  }

  setUserInfo(atk: string, rtk: string) {
    localStorage.setItem(constantVariables.accessToken, atk);
    localStorage.setItem(constantVariables.refreshToken, rtk);
    this.setUserInfoPayload(atk);
  }

  private setUserInfoPayload(atk: string) {
    try {
      const token: any = jwtDecode(atk);
      const user: IUser = {
        id: token.sub,
        email: token.usi.em,
        fullName: token.usi.fn,
        gender: token.usi.gn,
        avatar: token.usi.avt,
        username: token.usi.un,
        isFirstLogin: token.ifl,
        activeWorkspace: token.aw,
        // subscriptionData: token.sbc,
      };
      this.userInfo.set(user);
    } catch (error) {
      this.userInfo.set(undefined);
    }
  }
}
